


































































import { PageBase } from "@/core/models/shared";
import { OrdemServicoService } from "@/core/services/interna";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class RelatorioComItens extends PageBase {

    service: OrdemServicoService = new OrdemServicoService();

    item: any[] = [];

    overlay: boolean = false;

    mounted() {
        const filter = this.$route.query;

        this.overlay = true;

        this.service.RelatorioComItens(0,-1,[],[], null, [], filter)
            .then(
                res=>{
                    this.item = res.data.items;
                },
                err=>{
                    if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                    this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() => {
                this.overlay = false;
            });
    }
    
}
